import React from "react";
import styles from "../FundingRelease/index.module.css";
import Container from "../../Components/General/Container";
import { assetPath } from "../../assets/s3Uploads";

const EsopsBuyBackRelease = () => {
  return (
    <Container>
      <div className={styles.fundingContainer}>
        <div className={styles.fundingHeader}>
          NowPurchase completed its First-Ever ESOP Buyback, empowering
          Employees and Strengthening Growth
        </div>
        <img
          className={styles.fundingImg}
          src={assetPath.EsopsBuyBackMainImg}
          alt="Press Release"
        />
        <div className={styles.description}>
          <strong>
            Kolkata, November 21, 2024 -
            <a
              href="https://nowpurchase.com/"
              target="blank"
              rel="noopener noreferrer"
            >
              {" "}
              NowPurchase
            </a>
            ,
          </strong>{" "}
          a B2B startup for announced that it has completed its first-ever{" "}
          <b>Employee Stock Option Plan (ESOP) buyback</b>, marking a key
          milestone for the company and the startup ecosystem in Kolkata.
          Through this buyback, NowPurchase’s{" "}
          <b>current and former employees</b>—from key managers to field
          teams—were able to benefit financially.
        </div>
        <div className={styles.description}>
          The buyback, which was valued at approximately{" "}
          <b>100 times the initial purchase price</b>, has had a transformative
          impact on employees' lives. Participating team members shared their
          future plans in a video, expressing aspirations to invest in financial
          markets, purchase their first car, or take steps toward homeownership.
          Several key ESOP holders, including Sachin Singh (Business Head),
          Prateek Losalka (GM Finance), and Nishant Singh (Sr. Engineering
          Manager), who have been with NowPurchase since its inception and hold
          a substantial number of shares, chose not to sell their shares. The
          initiative emphasizes NowPurchase’s “people-first” approach and its
          dedication to creating a workplace where every employee, regardless of
          role, feels valued.
        </div>
        <div className={styles.description}>
          Following a recent <b>$6 million funding round</b>, which brought the
          company’s total funds raised to over $10 million, the ESOP buyback
          exemplifies NowPurchase’s commitment to inclusive growth. The recent
          funding round included equity and debt contributions from{" "}
          <b>InfoEdge Ventures</b>, <b>Orios Venture Partner</b>,{" "}
          <b>100 Unicorns</b>, <b>VC Grid</b> as well as several family offices
          and angel investors.
        </div>
        <div className={styles.description}>
          <strong> Naman Shah, Founder & CEO, NowPurchase</strong>
          <div className={styles.italicsText}>
            “We believe that building a startup is a team effort between
            investors, the founder, and employees, making the ESOP structure
            crucial.It brings me immense joy that our ESOP program, launched on
            July 1st, 2019, has now enabled team members to benefit from our
            shared success. The fact that this buyback included employees across
            all levels—warehouse staff, potential CXOs, and everyone in
            between—highlights NowPurchase’s commitment to growth and value
            creation for all.”
          </div>
        </div>
        <div className={styles.description}>
          <strong> Aakash Shah, Co-Founder, NowPurchase</strong>
          <div className={styles.italicsText}>
            “ESOPs have always been critical to both Naman and me. We believed
            that making our team true stakeholders would foster long-term
            commitment and alignment with our vision. With this buyback, we’ve
            opened up an opportunity for our team to see tangible returns from
            their efforts, and it’s inspiring to see so many choose to hold on
            to their shares, showing belief in our journey ahead.”
          </div>
        </div>
        <div className={styles.description}>
          Through initiatives like the ESOP buyback, NowPurchase continues to
          set a precedent for prioritizing employee well-being and creating
          long-term value for all stakeholders.
        </div>
        <div className={styles.footer}>
          <strong>About NowPurchase:</strong>
          <div className={styles.description}>
            NowPurchase was founded in 2017 by Naman Shah and Aakash Shah, with
            an initial investment of $300K from Nipha Group. The company draws
            on Naman's experience with multiple startups in the US and
            Singapore, where he witnessed firsthand the transformative power of
            technology for businesses. Aakash’s decade-plus manufacturing
            experience has helped the company with its supply chain & building
            the right solutions for metal manufacturers. Ankan Adhikari joined
            the core team in 2019 as a CTO. Ankan is a serial entrepreneur whose
            previous EdTech venture was acquired by UpGrad. With a comprehensive
            range of services and solutions, NowPurchase aims to expand its
            geographic footprint and become the leading procurement platform for
            all metal manufacturers. NowPurchase’s proprietary SaaS technology
            platform MetalCloud optimizes Charge Mix selection by analyzing
            inventory, real-time market prices, and supply conditions and
            integrates live market pricing, metallurgical inputs, and machine
            learning for seamless optimization, all accessible through a mobile
            dashboard.
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EsopsBuyBackRelease;
