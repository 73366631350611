import React from "react";
import GreenNarrativeOfScrapHero from "./Hero";
import SustainableMetal from "./SustainableMetal";
import TreePlantation from "./TreePlantation";
import MetalScrapEnv from "./MetalScrapEnv";
function GreenNarrativeOfScrap() {
  
  return(
    <>
    <GreenNarrativeOfScrapHero/>
    <SustainableMetal/>
    <MetalScrapEnv/>
    <TreePlantation/>
    </>
  )
}

export default GreenNarrativeOfScrap