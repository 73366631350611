import React from "react";
import "./index.css";
import addr1 from "../../../assets/images/contact/address_1.png";
import addr2 from "../../../assets/images/contact/address_2.png";
import addr3 from "../../../assets/images/contact/address_3.png";
import addr4 from "../../../assets/images/contact/address_4.png";
import { imagePaths } from "../../../constants";
function AddressSection() {
  return (
    <div className="addressDetails">
      <div className="container">
        <div className="addressRow">
          <h2 className="titleText aosAnim boldText ltr">Find us here</h2>
          <div className="addressBoxWrap aosAnim ltr">
            <div className="eachAddressBox">
              <div className="imgBox">
                <img loading="lazy" src={addr1} alt="" />
              </div>
              <div className="contactBox">
                <div>
                  <h4>Corporate Office</h4>
                  <p>
                    Kariwala Tower, EP Block, Sector V, Bidhannagar, Kolkata,
                    West Bengal <br />
                    700091
                  </p>
                </div>
              </div>
              <div className="btnBox">
                <a
                  href="https://goo.gl/maps/p2p8RqPuFrzdLQC8A"
                  className="npButton"
                  target="_blank"
                  id="OurAddresses_CorporateOffice_ViewOnMap"
                >
                  View on Map
                </a>
              </div>
            </div>
            <div className="eachAddressBox">
              <div className="imgBox">
                <img loading="lazy" src={addr4} alt="" />
              </div>
              <div className="contactBox">
                <div>
                  <h4>Pune Office</h4>
                  <p>
                    3rd & 4th Floor, G.K.Mall, Above Pantaloons, Near Kokane
                    ChowkPimple Saudagar Maharashtra- 411027
                  </p>
                </div>
              </div>
              <div className="btnBox">
                <a
                  href="https://g.co/kgs/3K5DYyF"
                  className="npButton"
                  rel="noreferrer"
                  target="_blank"
                  id="OurAddresses_KolhapurOffice_ViewOnMap"
                >
                  View on Map
                </a>
              </div>
            </div>
            <div className="eachAddressBox">
              <div className="imgBox">
                <img
                  loading="lazy"
                  src={imagePaths.AmtaunitImg}
                  alt="Amta Processing Unit "
                />
              </div>
              <div className="contactBox">
                <div>
                  <h4>Amta Processing Unit</h4>
                  <p>Serpur, West Bengal 711401</p>
                </div>
              </div>
              <div className="btnBox">
                <a
                  href="https://www.google.com/maps?sca_esv=658059ba84023065&sca_upv=1&rlz=1C5CHFA_enIN1075IN1079&gs_lp=Egxnd3Mtd2l6LXNlcnAiCUludGltIExhbSoCCAAyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyCBAAGIAEGKIEMggQABiABBiiBEicIVCVBliTGXABeAGQAQCYAa4CoAHRDaoBBzAuNy4xLjG4AQHIAQD4AQGYAgqgAo4OqAIUwgITEAAYgAQYQxi0AhiKBRjqAtgBAcICGRAuGIAEGEMYtAIYxwEYigUY6gIYrwHYAQHCAhYQLhgDGLQCGOUCGOoCGIwDGI8B2AECwgIWEAAYAxi0AhjlAhjqAhiMAxiPAdgBAsICCxAAGIAEGLEDGIMBwgIOEAAYgAQYsQMYgwEYigXCAg4QLhiABBixAxiDARiKBcICERAuGIAEGLEDGNEDGIMBGMcBwgIKEAAYgAQYQxiKBcICEBAAGIAEGLEDGEMYgwEYigXCAgsQABiABBiRAhiKBcICCxAuGIAEGLEDGIMBwgIOEAAYgAQYkQIYsQMYigXCAggQABiABBixA8ICCBAuGIAEGOUEwgIHEAAYgAQYCsICCxAAGIAEGIYDGIoFmAMUugYECAEYB7oGBggCEAEYCpIHBzEuNy4xLjGgB5FP&um=1&ie=UTF-8&fb=1&gl=in&sa=X&geocode=KUVtQPSAgwI6MZkHyc8R4Dec&daddr=Serpur,+West+Bengal+711401"
                  className="npButton"
                  target="_blank"
                  id="OurAddresses_KolhapurOffice_ViewOnMap"
                >
                  View on Map
                </a>
              </div>
            </div>
            <div className="eachAddressBox">
              <div className="imgBox">
                <img loading="lazy" src={addr2} alt="" />
              </div>
              <div className="contactBox">
                <div>
                  <h4>Kolhapur Address</h4>
                  <p>
                    Biz Hero India Pvt Ltd. – (NowPurchase)Plot no.12, Beside
                    Jafrani weight bridge,M.I.D.C shiroli, Kolhapur – 416122
                  </p>
                </div>
              </div>
              <div className="btnBox">
                <a
                  href="https://goo.gl/maps/ajyADxVXhyFqV6XN9"
                  className="npButton"
                  target="_blank"
                  id="OurAddresses_KolhapurOffice_ViewOnMap"
                >
                  View on Map
                </a>
              </div>
            </div>
            <div className="eachAddressBox">
              <div className="imgBox">
                <img loading="lazy" src={addr3} alt="" />
              </div>
              <div className="contactBox">
                <div>
                  <h4>Warehouse</h4>
                  <p>
                    J7MX+8RW, Shreeram Warehousing Complex, near Indian Oil
                    Petrol Pump, Chamrail, Howrah, West Bengal 711114
                  </p>
                </div>
              </div>
              <div className="btnBox">
                <a
                  href="https://goo.gl/maps/CLY5radcRTTrVShc9"
                  className="npButton"
                  target="_blank"
                  id="OurAddresses_Warehouse_ViewOnMap"
                >
                  View on Map
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressSection;
