import React from "react";
import "./index.css";
import mapIcon from "../../../assets/images/career/mapIcon.svg";
import calenderIcon from "../../../assets/images/career/calendar-2-line.svg";
import eventIFEX5 from "../../../assets/images/events/eventIFEX5.png";
import eventIFEX3 from "../../../assets/images/events/eventIFEX3.png";
import eventIFEX4 from "../../../assets/images/events/eventIFEX4.png";
import eventIFEX2 from "../../../assets/images/events/eventIFEX2.png";
import eventIfex1 from "../../../assets/images/events/IFEX 2024.jpeg";
import eventIfex6 from "../../../assets/images/events/IFFE 2K24.png";
import eascon from "../../../assets/images/events/EASCON 2024.png";
import eventBg from "../../../assets/images/events/eventBg.png";
import wfc2024 from "../../../assets/images/events/WFC 2024.png";
import ncon from "../../../assets/images/events/NCON.png";
import qmax from "../../../assets/images/events/QMax.png";
import wfo2024 from "../../../assets/images/events/WFO - 2024 - Monish.jpeg";
import iifb2b from "../../../assets/images/events/IIF B2B Meet.png";
import iess from "../../../assets/images/events/IESS.jpeg";

const eventsData = [
  // Past Events (sorted by most recent first)
  {
    id: 1,
    title: "IFEX 2024 – 20th International Foundry Exhibition",
    location: "Bangalore International Exhibition Centre (BIEC)",
    date: "2024-02-02",
    endDate: "2024-02-04",
    image: eventIfex1,
  },
  {
    id: 2,
    title: "EASCON - Re CASTING East",
    location: "Park Prime Hotel, Kolkata",
    date: "2024-01-13",
    image: eascon,
  },
  {
    id: 3,
    title: "ISSE 2023",
    location: "Helipad Exhibition Centre, Sector 17, Gandhinagar",
    date: "2023-11-28",
    endDate: "2023-11-30",
  },
  {
    id: 4,
    title: "IIM-ATM 2023",
    location: "KIIT, Bhubaneswar",
    date: "2023-11-22",
    endDate: "2023-11-24",
    image: eventIFEX2,
  },
  {
    id: 5,
    title: "ISME 2023",
    location: "Eco Park, Rajarhat, Kolkata",
    date: "2023-11-06",
    endDate: "2023-11-09",
  },
  {
    id: 6,
    title: "Bureau of Middle East Recycling (BMR)",
    location: "Dubai, United Arab Emirates",
    date: "2023-10-20",
    endDate: "2023-10-21",
  },
  {
    id: 7,
    title: "MAKH Annual General Meeting",
    location: "Hotel Pavillion, Shahupuri, Kolhapur",
    date: "2023-10-13",
    image: eventIFEX5,
  },
  {
    id: 8,
    title: "GIFA (SOUTH-EAST ASIA)",
    location: "Bangkok, Thailand",
    date: "2023-09-20",
    endDate: "2023-09-23",
  },
  {
    id: 9,
    title: "Goshima Annual General Meeting",
    location: "Pavillion Hotel, Assembly Road, Kolhapur",
    date: "2023-10-01",
    image: eventIFEX3,
  },
  {
    id: 10,
    title: "EO Tech Expo",
    location: "ITC Royal Bengal, Kolkata",
    date: "2023-04-22",
    image: eventIFEX2,
  },
  {
    id: 11,
    title: "All India Steel Conclave",
    location: "Mayfair Lake Resort, Raipur (C.G.), India",
    date: "2023-03-25",
    endDate: "2023-03-26",
    image: eventBg,
  },
  {
    id: 12,
    title: "Indian Foundry Congress & IFEX 2023",
    location: "India Expo Mart, Greater Noida, India",
    date: "2023-02-08",
    endDate: "2023-02-10",
    image: eventIFEX4,
  },

  // Upcoming Events (sorted by date)
  {
    id: 13,
    title: "IESS",
    location: "Codissia Trade Fair Complex, Coimbatore",
    date: "2024-03-04",
    endDate: "2024-03-06",
    image: iess,
  },
  {
    id: 14,
    title: "IIF B2B Meet",
    location: "Hotel Orchid, Mumbai",
    date: "2024-05-03",
    image: iifb2b,
  },
  {
    id: 15,
    title: "World Foundry Organization 2nd Young Researchers Conference",
    location: "Germany",
    date: "2024-06-11",
    image: wfo2024,
  },
  {
    id: 16,
    title: "Q-Max Tech Expo",
    location: "Ashok Leyland Ltd, Chennai",
    date: "2024-06-19",
    image: qmax,
  },
  {
    id: 17,
    title: "INTER FOUNDRY 2024",
    location: "Codissia Trade Fair Complex, Coimbatore",
    date: "2024-08-22",
    endDate: "2024-08-24",
  },
  {
    id: 18,
    title: "NCON'24",
    location: "Hotel Radisson City Centre, Jaipur",
    date: "2024-08-24",
    endDate: "2024-08-25",
    image: ncon,
  },
  {
    id: 19,
    title: "MAKH AGM 2024",
    location: "Hotel Pavilion, Kolhapur",
    date: "2024-09-29",
  },
  {
    id: 20,
    title: "World Foundry Congress",
    location: "Deyang, China",
    date: "2024-10-25",
    endDate: "2024-10-30",
    image: wfc2024,
  },
  {
    id: 21,
    title: "IFFE 2024",
    location: "JP Industrial Park, Kolhapur",
    date: "2024-11-10",
    endDate: "2024-11-12",
    image: eventIfex6,
  },
];

function EventsPage() {
  const sortEvents = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return {
      upcoming: eventsData
        .filter((event) => new Date(event.date) >= today)
        .sort((a, b) => new Date(a.date) - new Date(b.date)),
      past: eventsData
        .filter((event) => new Date(event.date) < today)
        .sort((a, b) => new Date(b.date) - new Date(a.date)), // Most recent first
    };
  };

  const formatDate = (startDate, endDate) => {
    const start = new Date(startDate);
    const options = { month: "long", day: "numeric", year: "numeric" };

    if (endDate) {
      const end = new Date(endDate);
      if (start.getMonth() === end.getMonth()) {
        return `${start.getDate()} - ${end.getDate()} ${start.toLocaleDateString(
          "en-US",
          { month: "long", year: "numeric" }
        )}`;
      }
      return `${start.toLocaleDateString(
        "en-US",
        options
      )} - ${end.toLocaleDateString("en-US", options)}`;
    }

    return start.toLocaleDateString("en-US", options);
  };

  const { upcoming, past } = sortEvents();

  return (
    <>
      <div className="event1">
        <div className="container">
          <div className="eventHead aosAnim">
            <h3 className="titleText">Previous Events</h3>
            <p>Listed are events that NowPurchase has been a part of.</p>
          </div>
          {past.map((event) =>
            event.image ? (
              // Render full event card with image
              <div className="eventFull aosAnim" key={event.id}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="eventImgM">
                      <img
                        loading="lazy"
                        style={{
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }}
                        src={event.image}
                        alt={event.title}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tabContBox">
                      <div className="topBar">
                        <h4>{event.title}</h4>
                      </div>
                      <div className="mainBox">
                        <div className="listBox">
                          <ul>
                            <li>
                              <img loading="lazy" src={mapIcon} alt="" />
                              <span>{event.location}</span>
                            </li>
                            <li>
                              <img loading="lazy" src={calenderIcon} alt="" />
                              <span>
                                {formatDate(event.date, event.endDate)}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // Render simple event card without image
              <div className="eventMWrap aosAnim" key={event.id}>
                <div className="tabContBox">
                  <div className="topBar">
                    <h4>{event.title}</h4>
                    <div className="listBox">
                      <ul>
                        <li>
                          <img loading="lazy" src={mapIcon} alt="" />
                          <span>{event.location}</span>
                        </li>
                        <li>
                          <img loading="lazy" src={calenderIcon} alt="" />
                          <span>{formatDate(event.date, event.endDate)}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mainBox"></div>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <section className="event1 event2 pt-0">
        <div className="container">
          <div className="eventHead aosAnim">
            <h3 className="titleText">Upcoming Events</h3>
            <p>
              Listed are events that NowPurchase is going to be a part of. These
              are tentative and can change at any time without prior notice.
            </p>
          </div>
          <div className="eventMWrap aosAnim">
            {upcoming.length > 0 ? (
              upcoming.map((event) => (
                <div className="tabContBox" key={event.id}>
                  <div className="topBar">
                    <h4>{event.title}</h4>
                    <div className="listBox">
                      <ul>
                        <li>
                          <img loading="lazy" src={mapIcon} alt="" />
                          <span>{event.location}</span>
                        </li>
                        <li>
                          <img loading="lazy" src={calenderIcon} alt="" />
                          <span>{formatDate(event.date, event.endDate)}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="tabContBox">
                <div className="topBar">
                  <h4>No Upcoming Events</h4>
                  <p style={{ marginTop: "10px" }}>
                    Check back later for future events.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default EventsPage;
