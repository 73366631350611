import React, { useLayoutEffect, useRef } from "react";
import Hero from "./Hero";
import TrustedProvenSolutions from "./TrustedProvenSol";
import TransformingIdeas from "./TransformingIdeas";
import OneStopSolutions from "./OneStopSolutions";
import CustomerCards from "../../Components/General/Cards/CustomerCards";
import Certificate from "./Certificate";
import NewlyLaunched from "./NewlyLaunched";
import { useLocation } from "react-router-dom";

const PremiumPrivateLabelQuery = "premium-private-labels";
function Marketplace() {
  const promotionRef = useRef(null);
  const location = useLocation();
  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isPremiumPrivateLabel = searchParams.has(PremiumPrivateLabelQuery);
    if (isPremiumPrivateLabel && promotionRef.current) {
      const rect = promotionRef.current.offsetTop;
      if (isPremiumPrivateLabel && promotionRef.current) {
        promotionRef.current.scrollIntoView({ top: rect, behavior: "smooth" });
      }
    }
  }, [promotionRef.current, location.search]);

  return (
    <>
      <Hero />
      <TrustedProvenSolutions />
      <TransformingIdeas />
      <OneStopSolutions />
      <NewlyLaunched ref={promotionRef} />
      <CustomerCards />
      <Certificate />
    </>
  );
}

export default Marketplace;
