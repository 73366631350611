import React, { useRef, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./NewsRoom.module.css";
import Marquee from "react-fast-marquee";
import img12 from "../../assets/images/logo/image_12.png";
import RightArrow from "../../assets/images/icons/rgt_arrow_vector_bl.png";
import img13 from "../../assets/images/logo/image_13.png";
import Inc42Logo from "../../assets/images/logo/Inc42Logo.svg";
import DisruptLogo from "../../assets/images/logo/bw-disrupt-logo.png";
import OutlookLogo from "../../assets/images/logo/start-up-logo.webp";
import MtiLogo from "../../assets/images/logo/ManufacturingToday.png";
import EntrackerLogo from "../../assets/images/logo/entracker-logo.png";
import StartupStoryLogo from "../../assets/images/logo/startup-logo.png";
import ZeeBusinessLogo from "../../assets/images/logo/zee-business.avif";
import NewsDrumLogo from "../../assets/images/logo/NewsDrum.webp";
import BusinessLineLogo from "../../assets/images/logo/business-line-logo.svg";
import VCCLogo from "../../assets/images/logo/VCC-logo.svg";
import viestories from "../../assets/images/logo/viestories.png";
import rediff from "../../assets/images/logo/rediff.png";
import devdiscourse_logo from "../../assets/images/logo/devdiscourse.png";
import IndianStartupNewsLogo from "../../assets/images/logo/IndianStartupNews.webp";
import img14 from "../../assets/images/logo/image_14.png";
import img16 from "../../assets/images/logo/image_16.png";
import img2 from "../../assets/images/logo/image_2.png";
import CrescentLogo from "../../assets/images/logo/CrescentFoundry.png";
import JupiterLogo from "../../assets/images/logo/image.png";
import cnbctv from "../../assets/images/logo/cnbc-logo.png";
import RBAgarwalla from "../../assets/images/logo/RB Agarwalla.png";
import img5 from "../../assets/images/logo/image_5.png";
import NIPHA from "../../assets/images/logo/NIPHA.png";
import ShantiFouMach from "../../assets/images/logo/ShantiLogo.png";
import media5 from "../../assets/images/home/media/media5.png";
import media6 from "../../assets/images/home/media/media6.png";
import media8 from "../../assets/images/home/media/media8.png";
import media9 from "../../assets/images/home/media/media9.png";
import media10 from "../../assets/images/home/media/media10.png";
import FTmedia from "../../assets/images/home/media/FTnew.png";
import TheEconimicTimes from "../../assets/images/logo/TheEconomicTimes.png";
import BusinessStandard from "../../assets/images/logo/BusinessStandard.png";
import media1 from "../../assets/images/home/media/media3.png";
import media2 from "../../assets/images/home/media/media1.png";
import media3 from "../../assets/images/home/media/media2.png";
import media4 from "../../assets/images/home/media/media4.png";
import Mint from "../../assets/images/logo/mintLogo.png";
import Container from "../../Components/General/Container";
import MediaInvestor from "./MediaInvestor";
import MediaCardMobileView from "./MediaCardMobileView";

const Logos = [
  img14,
  img2,
  img12,
  CrescentLogo,
  img13,
  RBAgarwalla,
  img5,
  JupiterLogo,
  NIPHA,
  ShantiFouMach,
  img16,
];

export const mediaArticles = [
  {
    image: media3,
    title:
      "B2B commerce startup NowPurchase raises $6 million in a mix of equity and debt",
    readTime: "",
    redirectLink:
      "https://economictimes.indiatimes.com/tech/funding/saas-startup-nowpurchase-raises-6-million-in-a-mix-of-equity-and-debt/articleshow/113474933.cms",
  },
  {
    image: BusinessLineLogo,
    title: "NowPurchase secures funding of $6 million",
    redirectLink:
      "https://www.thehindubusinessline.com/markets/commodities/nowpurchase-secures-funding-of-6-million/article68659706.ece",
  },
  {
    image: media2,
    title: "NowPurchase raises $6M led by Info Edge Ventures",
    readTime: "",
    redirectLink:
      "https://yourstory.com/2024/09/nowpurchase-raises-6m-led-by-info-edge-ventures",
  },
  {
    image: Inc42Logo,
    title:
      "NowPurchase Nets $6 Mn To Offer Tech-Led Procurement Solutions To Metal Manufacturers",
    readTime: "",
    redirectLink:
      "https://inc42.com/buzz/nowpurchase-nets-6-mn-to-offer-tech-led-procurement-solutions-to-metal-manufacturers-funding/",
  },
  {
    image: cnbctv,
    title:
      "B2B marketplace NowPurchase raises $6 million from Info Edge Ventures, Orios VP & others",
    redirectLink:
      "https://www.cnbctv18.com/business/b2b-marketplace-nowpurchase-raises-usd-6-million-from-info-edge-ventures-orios-vp-others-19478579.htm",
  },
  {
    image: VCCLogo,
    title: "NowPurchase, TechJockey, Bookmybai Secure Early-Stage Fundings",
    redirectLink:
      "https://www.vccircle.com/nowpurchasetechjockey-bookmybai-secure-early-stage-funding",
  },
  {
    image: DisruptLogo,
    title:
      "NowPurchase Raises $6 Mn From Info Edge Ventures, Orios,100 Unicorns, Others",
    readTime: "",
    redirectLink:
      "https://bwdisrupt.com/article/nowpurchase-raises-6-mn-from-info-edge-ventures-orios100-unicorns-others-533596",
  },
  {
    image: OutlookLogo,
    title: "NowPurchase Raises USD $ 6 Million In Funding Round",
    readTime: "",
    redirectLink:
      "https://www.outlookbusiness.com/start-up/nowpurchase-raises-usd-6-million-in-funding-round",
  },
  {
    image: EntrackerLogo,
    title: "NowPurchase raises $6 Mn led by Info Edge Ventures, others",
    readTime: "",
    redirectLink:
      "https://entrackr.com/2024/09/nowpurchase-raises-6-mn-led-by-info-edge-ventures-others/",
  },
  {
    image: StartupStoryLogo,
    title:
      "NowPurchase Secures $6 Million in Funding Led by Info Edge Ventures",
    readTime: "",
    redirectLink:
      "https://startupstorymedia.com/insights-nowpurchase-secures-6-million-in-funding-led-by-info-edge-ventures/#google_vignette",
  },
  {
    image: IndianStartupNewsLogo,
    title:
      "SaaS-enabled marketplace for metal manufacturers NowPurchase raises $6 million",
    readTime: "",
    redirectLink:
      "https://indianstartupnews.com/funding/saas-enabled-marketplace-for-metal-manufacturers-nowpurchase-raises-6-million-7078498",
  },
  {
    image: MtiLogo,
    title:
      "NowPurchase raises US$ 6 million in latest funding round to expand metal SaaS marketplace",
    readTime: "",
    redirectLink:
      "https://www.manufacturingtodayindia.com/nowpurchase-raises-us-6-million-in-latest-funding-round-to-expand-metal-saas-marketplace",
  },
  {
    image: ZeeBusinessLogo,
    title: "SaaS startup NowPurchase raises $6 million in funding round",
    readTime: "",
    redirectLink:
      "https://www.zeebiz.com/startups/news-startup-funding-news-saas-firm-nowpurchase-raises-usd-6-mn-from-info-edge-naukricom-316114",
  },
  {
    image: NewsDrumLogo,
    title: "NowPurchase raises USD 6 mn in funding round",
    readTime: "",
    redirectLink:
      "https://www.newsdrum.in/business/nowpurchase-raises-usd-6-mn-in-funding-round-7078031",
  },
  {
    image: viestories,
    title: "NowPurchase Secures $6 Mn Funding Round from Info Edge Ventures",
    readTime: "",
    redirectLink:
      "https://viestories.com/nowpurchase-secures-6-mn-funding-round/?amp=1",
  },
  {
    image: devdiscourse_logo,
    title:
      "NowPurchase Raises $6 Million to Boost Metal Manufacturing Solutions",
    readTime: "",
    redirectLink:
      "https://www.devdiscourse.com/article/business/3091501-nowpurchase-raises-6-million-to-boost-metal-manufacturing-solutions",
  },
  {
    image: rediff,
    title: "NowPurchase Raises USD 6 Million in Funding Round",
    readTime: "",
    redirectLink:
      "https://money.rediff.com/news/market/nowpurchase-raises-usd-6-million-in-funding-round/15937820240919",
  },
];

const Logo = ({ src }) => <img src={src} alt="logo" className={styles.logo} />;

export const LogoContainer = () => (
  <Marquee
    gradientColor="transparent"
    speed={40}
    gradient={true}
    gradientWidth={100}
    pauseOnHover={true}
  >
    {Logos.map((logo, index) => (
      <Logo key={index} src={logo} />
    ))}
  </Marquee>
);

const Card = ({ children, className }) => (
  <div className={`${styles.card} ${className}`}>{children}</div>
);

const Section = ({ title, children }) => (
  <motion.section
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.8 }}
    className={styles.newsroomSection}
  >
    <h2 className={styles.sectionTitle}>{title}</h2>
    <div className={styles.sectionContent}>{children}</div>
  </motion.section>
);

export const MediaRow = ({ articles }) => {
  const scrollRef = useRef(null);
  const [showLeftFade, setShowLeftFade] = useState(false);
  const [showRightFade, setShowRightFade] = useState(true);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftFade(scrollLeft > 0);
      setShowRightFade(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
      handleScroll(); // Check initial state
      return () => scrollElement.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <>
      <div className={`${styles.mediaRowContainer} ${styles.desktopView}`}>
        {showLeftFade && <div className={styles.gradientLeft}></div>}
        {showRightFade && <div className={styles.gradientRight}></div>}
        <div className={styles.mediaRow} ref={scrollRef}>
          {articles.map((article, index) => (
            <a
              key={index}
              href={article.redirectLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <motion.div
                className={styles.articleCard}
                whileHover={{ scale: 1.05 }}
              >
                <Card>
                  <div className={styles.articleContent}>
                    <img
                      src={article.image}
                      alt={article.title}
                      className={styles.articleImage}
                    />
                    <p className={styles.articleTitle}>{article.title}</p>
                  </div>
                  <div className={styles.articleMeta}>
                    <div
                      className={styles.mediaRedirectBtn}
                      id="Media_Section7_MediaMoments_Card1"
                    >
                      <span className={styles.readMoreText}>Read More</span>
                      <img src={RightArrow} />
                    </div>
                    {/* <span>{article.date}</span> */}
                  </div>
                </Card>
              </motion.div>
            </a>
          ))}
        </div>
      </div>
      <MediaCardMobileView displayItems={articles} />
    </>
  );
};

const AwardsRow = ({ items }) => {
  const scrollRef = useRef(null);
  const [showLeftFade, setShowLeftFade] = useState(false);
  const [showRightFade, setShowRightFade] = useState(true);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftFade(scrollLeft > 0);
      setShowRightFade(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
      handleScroll(); // Check initial state
      return () => scrollElement.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <div className={styles.mediaRowContainer}>
      {showLeftFade && <div className={styles.gradientLeft}></div>}
      {showRightFade && <div className={styles.gradientRight}></div>}
      <div className={styles.mediaRow} ref={scrollRef}>
        {items.map((item, index) => (
          <motion.div
            key={index}
            className={styles.mediaCard}
            whileHover={{ scale: 1.05 }}
          >
            <Card className={styles.awardCard}>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={item.image}
                  alt={item.title}
                  className={styles.mediaImage}
                />
                <div className={styles.mediaContent}>
                  <h3 className={styles.mediaTitle}>{item.title}</h3>
                  {item.description}
                </div>
              </a>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

const NewsRoom = () => {
  const awards = [
    {
      description: (
        <div class="mediaTextAcc">
          <p>
            NowPurchase is proud to be a member of the{" "}
            <strong>Recycled Materials Association</strong>, supporting
            sustainability, resilience, and essential recycling practices.
          </p>
        </div>
      ),
      image: media9,
      link: "https://www.isri.org/",
    },
    {
      description: (
        <div class="mediaTextAcc">
          <p>
            Our paper on MetalCloud was honoured as a{" "}
            <strong>Top 10 Selection</strong> at the WFO Young Researchers
            Conference 2024
          </p>
        </div>
      ),
      image: media10,
      link: "https://www.thewfo.com/",
    },
    {
      description: (
        <div class="mediaTextAcc">
          <p>
            Top
            <strong> 500 High-Growth Companies </strong>in the Asia-Pacific
            Region
          </p>
        </div>
      ),
      image: FTmedia,
      link: "https://www.ft.com/content/dbac4faa-5a8c-4ba9-8425-12ae727e0d05",
    },
    {
      description: (
        <div class="mediaTextAcc">
          <p>
            Featured in <strong>Financial Times Fastest Growing List</strong>
          </p>
        </div>
      ),
      image: media5,
      link: "https://www.ft.com/high-growth-asia-pacific-ranking-2023",
    },
    {
      description: (
        <div class="mediaTextAcc">
          <p>
            Received <br />
            <strong>Dun & Bradstreet Startup50 2023</strong> Award
          </p>
        </div>
      ),
      image: media6,
      link: "https://www.dnb.co.in/file/publications/the-next-big-leap/54/",
    },
    {
      description: (
        <div class="mediaTextAcc">
          <p>
            Featured in{" "}
            <strong>
              {" "}
              The Economic Times Top 50 India’s Growth Champions 2023
            </strong>
          </p>
        </div>
      ),
      image: media8,
      link: "https://economictimes.indiatimes.com/indias-growth-champions-2023-ranks-nations-fastest-growing-companies/articleshow/98454662.cms?",
    },
  ];

  return (
    <AnimatePresence>
      <Container>
        <div className={styles.titleContainer}>
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.8 }}
            className={styles.title}
          >
            <span style={{ color: "#1579be" }} className={styles.title}>
              NowPurchase
            </span>{" "}
            Newsroom
          </motion.h1>
        </div>

        <Section
          title={
            <>
              Our <span className={styles.skyText}>Media</span> Moments!
            </>
          }
        >
          <MediaRow articles={mediaArticles} />
        </Section>

        <Section
          title={
            <>
              Our <span className={styles.skyText}>Accolades</span> &{" "}
              <span className={styles.skyText}>Associations</span>
            </>
          }
        >
          <AwardsRow items={awards} />
        </Section>
      </Container>
      <MediaInvestor />
    </AnimatePresence>
  );
};

export default NewsRoom;
