import React, { useState } from "react";
import { Modal, Form, Button, SelectPicker, Input, Checkbox } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import CheckIcon from "@rsuite/icons/Check";
import "./index.css";

const Index = ({ modalOpen, setModalOpen }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [enquiryPurpose, setEnquiryPurpose] = useState("");
  const [remarks, setRemarks] = useState("");
  const [optInValue, setOptInValue] = useState(true);

  const handleClose = () => {
    setModalOpen(false);
  };
  const optionsData = [
    "MarketPlace",
    "MetalCloud",
    "Careers",
    "Request Demo",
    "Others",
  ].map((items) => ({ label: items, value: items }));

  const handleSubmit = async (e) => {
    // Validate form fields
    const namePattern = /^[a-zA-Z\s]*$/;
    const phonePattern = /^\d+$/;

    if (!name || !namePattern.test(name)) {
      alert(
        "Please enter a valid name. Only alphabets and spaces are allowed."
      );
      return;
    }

    if (!phone || !phonePattern.test(phone)) {
      alert("Please enter a valid phone number. Only numbers are allowed.");
      return;
    }

    if (!enquiryPurpose) {
      alert("Please select your enquiry purpose.");
      return;
    }

    if (!remarks) {
      alert("Please enter remarks");
      return;
    }

    const payload = {
      text: `New enquiry:
            Name: ${name}
            Contact Number: ${phone}
            Enquiry Purpose: ${enquiryPurpose}
            Remarks: ${remarks}
            Opt in for WhatsApp Notfications: ${optInValue}`,
    };

    try {
      await notifyOnSlack("NP_WEBSITE", payload.text);
      await fetch(
        "https://script.google.com/macros/s/AKfycbyoobWWZUNeJn9ocXTjIIbq-iwdMlJ0Fh78HU7pFVoE6YPMvrDCxGtam-_u8ZNEYzA0/exec",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            contactNumber: phone,
            enquiryPurpose,
            remarks,
            optInWhatsApp: optInValue,
          }),
        }
      );

      setModalOpen(false);
    } catch (error) {
      alert("Error submitting enquiry");
    } finally {
      // Reset form fields
      setName("");
      setPhone("");
      setEnquiryPurpose("");
      setRemarks("");
    }
  };

  const notifyOnSlack = async (channel, message) => {
    try {
      const response = await fetch(
        "https://test-api.nowpurchase.com/api/send_slack/",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            channel: channel,
            message: message,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to submit enquiry");
      }

      return response;
    } catch (error) {
      console.error("Fetch error:", error);
      throw error;
    }
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(
        "https://img1.nowpurchase.com/foundry/pitch_deck_sales.pdf"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Brochure.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download error:", error);
      alert("Error downloading file");
    }
  };
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Brochure Download / Enquiry Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid onSubmit={handleSubmit}>
          <Form.Group>
            <Form.ControlLabel for="name">
              <span>Name</span>
            </Form.ControlLabel>
            <Form.Control
              name="name"
              type="text"
              placeholder="Enter your name here"
              Required
              value={name}
              onChange={(value) => setName(value)}
            />
            {/* <Form.HelpText>Required</Form.HelpText> */}
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel for="phone">
              <span>Contact Number</span>
            </Form.ControlLabel>
            <Form.Control
              name="phone"
              type="tel"
              placeholder="Enter your contact number"
              value={phone}
              onChange={(value) => setPhone(value)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel for="entry_purpose">
              <span>Enquiry Purpose</span>
            </Form.ControlLabel>
            {/* <Form.Control
            name="entry_purpose"
            componentClass="select"
            placeholder="Select"
            required
          > */}

            <SelectPicker
              data={optionsData}
              value={enquiryPurpose}
              placeholder="Select Enquiry Purpose"
              searchable={false}
              size="sm"
              className="modalSelectPicker"
              onChange={(value) => setEnquiryPurpose(value)}
              style={{
                display: "flex",
                fontSize: "12px",
              }}
            />
            {/* </Form.Control> */}
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel for="remark">
              <span>Remark</span>
            </Form.ControlLabel>
            <Input
              value={remarks}
              onChange={(value) => setRemarks(value)}
              as="textarea"
              name="remark"
              rows={7}
              placeholder="Enter your remarks here"
            />
          </Form.Group>
          <Form.Group>
            <Checkbox
              onChange={() => setOptInValue((val) => !val)}
              value={optInValue}
              defaultChecked
            >
              Opt in for WhatsApp Notfications
            </Checkbox>
          </Form.Group>

          <Form.Group style={{ float: "right" }}>
            <Button
              appearance="subtle"
              onClick={handleDownload}
              style={{ marginRight: "20px" }}
            >
              <FileDownloadIcon /> Download Brochure
            </Button>
            <Button type="submit">
              <CheckIcon /> Submit Enquiry
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Index;
