import React, { useEffect } from "react";
import "./index.css";
import { MediaRow, mediaArticles } from "../../../pages/NewsRoom";
function MediaCards() {
  useEffect(() => {
    const aosElements = document.querySelectorAll(".aosAnim");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("ltr");
            // entry.target.classList.remove("ltr-out");
          } else {
            // entry.target.classList.add("ltr-out");
            // entry.target.classList.remove("ltr");
          }
        });
      },
      {
        threshold: 0.3,
        // rootMargin: "-10px",
      }
    );

    aosElements.forEach((el) => {
      observer.observe(el);
    });

    // Clean up the observer on component unmount
    return () => {
      aosElements.forEach((el) => {
        observer.unobserve(el);
      });
    };
  }, []);
  return (
    <div
      className="mediaSectionWrap animated-section section aosAnim "
      id="sec17"
    >
      <div className="scrollSection">
        <div className="container">
          <div className="mediaRow">
            <h2 className="titleText" style={{ lineHeight: "1.2" }}>
              Our <span className="skyText">Media</span> Moments!
            </h2>
            <MediaRow articles={mediaArticles} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaCards;
