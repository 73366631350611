import "./index.css";
import React, { forwardRef, useEffect, useState } from "react";
import graphimax from "../../../assets/images/marketplace/graphimax_mockup.png";
import carbonmax from "../../../assets/images/marketplace/carbonmax_mockup.png";
import barimax from "../../../assets/images/marketplace/barimax.png";
import magmax from "../../../assets/images/marketplace/magmax_mockup.png";
import {
  Modal,
  SelectPicker,
  Form,
  Button,
  toaster,
  useToaster,
  Message,
  Loader,
} from "rsuite";
const NewlyLaunched = forwardRef((props, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [enquiryPurpose, setEnquiryPurpose] = useState("");
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);
  const toaster = useToaster();

  const handleClose = () => {
    setModalOpen(false);
  };

  const isMobile = () => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    return mobile || window.innerWidth <= 768;
  };
  const successMessage = (
    <Message showIcon type={"success"}>
      <strong>Yaay!!</strong> Your Enquiry is placed.
    </Message>
  );

  const rejectMessage = (
    <Message showIcon type={"error"}>
      <strong>Oh no!!</strong> Something went wrong.
    </Message>
  );

  const optionsData = ["Graphi_Max", "Carbon_Max", "Mag_Max", "Bari_Max"].map(
    (item) => ({
      label: item,
      value: item,
    })
  );

  const handleSubmit = async (e) => {
    setLoading(true);
    const formData = {
      name: name,
      contact: phone,
      email: email,
      product: enquiryPurpose,
      remarks: remarks,
    };

    try {
      await fetch(
        "https://script.google.com/macros/s/AKfycbwltG86SJffJ2-g_yRI_MJtlvMZaLpwwhRD8rvPcXZpcI5WCs2rUCS_x0RlTj7MLmo/exec",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      toaster.push(successMessage, { placement: "topEnd", duration: 2000 });
      setName("");
      setPhone("");
      setEnquiryPurpose("");
      setRemarks("");
      setModalOpen(false);
    } catch (error) {
      console.error("Error submitting enquiry:", error);
      toaster.push(rejectMessage, { placement: "topEnd", duration: 2000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal open={modalOpen} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h3>Contact Us</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "590px" }}>
          <Form fluid onSubmit={handleSubmit}>
            <Form.Group>
              <Form.ControlLabel htmlFor="name">
                Name <span>*</span>
              </Form.ControlLabel>
              <Form.Control
                name="name"
                type="text"
                placeholder="Enter your name here"
                required
                value={name}
                onChange={(value) => setName(value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel htmlFor="phone">
                Contact Number <span>*</span>
              </Form.ControlLabel>
              <Form.Control
                name="phone"
                type="tel"
                placeholder="Enter your contact number"
                required
                value={phone}
                onChange={(value) => setPhone(value.replace(/\D/g, ""))}
              />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel htmlFor="phone">
                Email ID <span>*</span>
              </Form.ControlLabel>
              <Form.Control
                name="email"
                type="email"
                placeholder="Enter your email ID"
                required
                value={email}
                onChange={(value) => setEmail(value)}
              />
            </Form.Group>

            {/* <Form.Group>
              <Form.ControlLabel htmlFor="phone">
                Company Name <span>*</span>
              </Form.ControlLabel>
              <Form.Control
                name="companyName"
                type="text"
                placeholder="Enter your company name"
                required
                value={companyName}
                onChange={(value) => setCompanyName(value)}
              />
            </Form.Group> */}

            <Form.Group>
              <Form.ControlLabel htmlFor="entry_purpose">
                Enquiry Purpose <span>*</span>
              </Form.ControlLabel>
              <SelectPicker
                data={optionsData}
                required
                value={enquiryPurpose}
                searchable={false}
                className="modalSelectPicker"
                onChange={(value) => setEnquiryPurpose(value)}
                style={{ display: "flex", lineHeight: "22px !important" }}
              />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel htmlFor="remark">
                Remarks <span>*</span>
              </Form.ControlLabel>
              <Form.Control
                name="remark"
                placeholder="Enter your remarks here"
                required
                value={remarks}
                onChange={(value) => setRemarks(value)}
              />
            </Form.Group>

            <Form.Group style={{ float: "right" }}>
              <Button
                appearance="subtle"
                onClick={handleClose}
                style={{ marginRight: "20px" }}
              >
                Cancel
              </Button>
              <Button appearance="primary" type="submit" disabled={loading}>
                {loading ? <Loader /> : "Submit Enquiry"}
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
      <div
        class="mediaSectionWrap mediaSectionWrapA animated-section section aosAnim"
        id="sec17"
        ref={!isMobile() ? ref : null}
      >
        <div class="scrollSection">
          <div class="container">
            <div class="mediaRow1">
              <div className="sechead keyHead mb-8 aosAnim ">
                <h2 class="titleText">
                  <span class="skyText">Newly Launched </span>Flagship Range
                </h2>
                <p>
                  Cutting-edge solution designed to optimize production and
                  ensure superior casting results with guaranteed consistency in
                  material quality.
                </p>
              </div>
              <div class="mediaWrap mediaSlider">
                <div class="eachMediaBoxAcc1">
                  <figure>
                    <img loading="lazy" src={graphimax} alt="" />
                  </figure>
                  <div class="mediaTextAcc1">
                    <p>
                      A high-carbon material with ultra-low sulphur, ensuring
                      defect-free castings.
                    </p>
                    <a
                      onClick={() => {
                        setModalOpen(true);
                        setEnquiryPurpose("Graphi_Max");
                      }}
                      style={{ color: "white" }}
                      href="#getInTouchForm"
                      className="knowMoreBtn gradientBtn Enquire"
                    >
                      Enquire Now
                    </a>
                  </div>
                </div>
                <div class="eachMediaBoxAcc1">
                  <figure>
                    <img loading="lazy" src={carbonmax} alt="" />
                  </figure>
                  <div class="mediaTextAcc1" ref={isMobile() ? ref : null}>
                    <p>
                      Premium-quality carbon with low sulphur for high-quality
                      castings.
                    </p>
                    <a
                      onClick={() => {
                        setModalOpen(true);
                        setEnquiryPurpose("Carbon_Max");
                      }}
                      style={{ color: "white" }}
                      href="#getInTouchForm"
                      className="knowMoreBtn gradientBtn Enquire"
                    >
                      Enquire Now
                    </a>
                  </div>
                </div>

                <div class="eachMediaBoxAcc1">
                  <figure>
                    <img loading="lazy" src={magmax} alt="" />
                  </figure>
                  <div class="mediaTextAcc1">
                    <p>
                      High-grade nodularization for superior ductile iron
                      quality and casting efficiency.
                    </p>
                    <a
                      onClick={() => {
                        setModalOpen(true);
                        setEnquiryPurpose("Mag_Max");
                      }}
                      style={{ color: "white" }}
                      href="#getInTouchForm"
                      className="knowMoreBtn gradientBtn Enquire"
                    >
                      Enquire Now
                    </a>
                  </div>
                </div>

                <div class="eachMediaBoxAcc1">
                  <figure>
                    <img loading="lazy" src={barimax} alt="" />
                  </figure>
                  <div class="mediaTextAcc1">
                    <p>
                      Enhanced nodule formation for increased strength,
                      uniformity, and quality in cast iron.
                    </p>
                    <a
                      onClick={() => {
                        setModalOpen(true);
                        setEnquiryPurpose("Bari_Max");
                      }}
                      style={{ color: "white" }}
                      href="#getInTouchForm"
                      className="knowMoreBtn gradientBtn Enquire"
                    >
                      Enquire Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default NewlyLaunched;
