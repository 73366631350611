import React, { useEffect } from "react";
import "./index.css";
import media5 from "../../../assets/images/home/media/media5.png";
import media6 from "../../../assets/images/home/media/media6.png";
import media8 from "../../../assets/images/home/media/media8.png";
import media9 from "../../../assets/images/home/media/media9.png";
import media10 from "../../../assets/images/home/media/media10.png";
import FTmedia from "../../../assets/images/home/media/FTnew.png";
import MRAICertificate from "../../../assets/images/home/media/MRAI.jpg";
import IIFCertificate from "../../../assets/images/home/media/IIF.png";

function AccoladesAssociations() {
  useEffect(() => {
    const aosElements = document.querySelectorAll(".aosAnim");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("ltr");
            // entry.target.classList.remove("ltr-out");
          } else {
            // entry.target.classList.add("ltr-out");
            // entry.target.classList.remove("ltr");
          }
        });
      },
      {
        threshold: 0.3,
      }
    );

    aosElements.forEach((el) => {
      observer.observe(el);
    });

    // Clean up the observer on component unmount
    return () => {
      aosElements.forEach((el) => {
        observer.unobserve(el);
      });
    };
  }, []);
  return (
    <div
      class="mediaSectionWrap mediaSectionWrapA animated-section section aosAnim"
      id="sec17"
    >
      <div class="scrollSection">
        <div class="container">
          <div class="mediaRow">
            <h2 class="titleText">
              Our <span class="skyText">Accolades</span> & <br />{" "}
              <span class="skyText">Associations</span>
            </h2>
            <div class="mediaWrapAcc mediaSlider ">
              <div class="eachMediaBoxAcc">
                <a href="https://www.isri.org/" target="_blank">
                  <figure>
                    <img
                      loading="lazy"
                      src={media9}
                      alt=""
                      style={{
                        borderRadius: "20px 20px 0 0",
                      }}
                    />
                  </figure>
                  <div class="mediaTextAcc">
                    <p>
                      NowPurchase is proud to be a member of the{" "}
                      <strong>Recycled Materials Association</strong>,
                      supporting sustainability, resilience, and essential
                      recycling practices.
                    </p>
                  </div>
                </a>
              </div>
              <div class="eachMediaBoxAcc">
                <a href="https://www.thewfo.com/" target="_blank">
                  <figure>
                    <img
                      loading="lazy"
                      src={media10}
                      alt=""
                      style={{
                        borderRadius: "20px 20px 0 0",
                      }}
                    />
                  </figure>
                  <div class="mediaTextAcc">
                    <p>
                      Our paper on MetalCloud was honoured as a{" "}
                      <strong>Top 10 Selection</strong>
                      at the WFO Young Researchers Conference 2024
                    </p>
                  </div>
                </a>
              </div>
              <div class="eachMediaBoxAcc">
                <a
                  href="https://www.ft.com/content/dbac4faa-5a8c-4ba9-8425-12ae727e0d05"
                  target="_blank"
                >
                  {" "}
                  <figure>
                    <img
                      loading="lazy"
                      src={FTmedia}
                      alt=""
                      style={{
                        borderRadius: "20px 20px 0 0",
                      }}
                    />
                  </figure>
                  <div class="mediaTextAcc">
                    <p>
                      Top
                      <strong> 500 High-Growth Companies </strong>in the
                      Asia-Pacific Region
                    </p>
                  </div>
                </a>
              </div>
              <div class="eachMediaBoxAcc">
                <a
                  href="https://www.ft.com/high-growth-asia-pacific-ranking-2023"
                  target="_blank"
                >
                  <figure>
                    <img loading="lazy" src={media5} alt="" />
                  </figure>
                  <div class="mediaTextAcc">
                    <p>
                      Featured in{" "}
                      <strong>Financial Times Fastest Growing List</strong>
                    </p>
                  </div>
                </a>
              </div>
              <div class="eachMediaBoxAcc">
                <a
                  href="https://www.dnb.co.in/file/publications/the-next-big-leap/54/"
                  target="_blank"
                >
                  <figure
                    style={{
                      background: "#f0f0f0",
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                  >
                    <img loading="lazy" src={media6} alt="" />
                  </figure>
                  <div class="mediaTextAcc">
                    <p>
                      Received <br />
                      <strong>Dun & Bradstreet Startup50 2023</strong> Award
                    </p>
                  </div>
                </a>
              </div>
              <div class="eachMediaBoxAcc">
                <a
                  href="https://economictimes.indiatimes.com/indias-growth-champions-2023-ranks-nations-fastest-growing-companies/articleshow/98454662.cms?"
                  target="_blank"
                >
                  <figure>
                    <img loading="lazy" src={media8} alt="" />
                  </figure>
                  <div class="mediaTextAcc">
                    <p>
                      Featured in{" "}
                      <strong>
                        {" "}
                        The Economic Times Top 50 India’s Growth Champions 2023
                      </strong>
                    </p>
                  </div>
                </a>
              </div>
              <div class="eachMediaBoxAcc">
                <a href="https://mrai.org.in/" target="_blank">
                  <figure>
                    <img loading="lazy" src={MRAICertificate} alt="" />
                  </figure>
                  <div class="mediaTextAcc">
                    <p>
                      We are now a member of{" "}
                      <strong>Material Recycling Association Of India</strong>
                    </p>
                  </div>
                </a>
              </div>
              <div class="eachMediaBoxAcc">
                <a
                  href="https://www.indianfoundry.org/membership.php"
                  target="_blank"
                >
                  <figure>
                    <img loading="lazy" src={IIFCertificate} alt="" />
                  </figure>
                  <div class="mediaTextAcc">
                    <p>
                      Featured in{" "}
                      <strong> The Institute of Indian Foundrymen</strong>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccoladesAssociations;
