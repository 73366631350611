import React, { useEffect, useRef } from "react";
import "./index.css";
import culturePic from "../../../assets/images/home/CultureSectionMainImg.png";
import culturePic1 from "../../../assets/images/home/cultureImg1.png";
import culturePic2 from "../../../assets/images/home/cultureImg2.png";
import culturePic3 from "../../../assets/images/home/cultureImg3.png";
import RightHighlightedArrow from "../../../assets/images/icons/rgt_arrow_vector_bl.png";
import { useNavigate } from "react-router-dom";
function CultureSection() {
  const aosRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("ltr");
            // entry.target.classList.remove("ltr-out");
          } else {
            // entry.target.classList.add("ltr-out");
            // entry.target.classList.remove("ltr");
          }
        });
      },
      {
        threshold: 0.3,
        // rootMargin: "-10px",
      }
    );

    if (aosRef.current) {
      observer.observe(aosRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (aosRef.current) {
        observer.unobserve(aosRef.current);
      }
    };
  }, []);
  return (
    <section
      ref={aosRef}
      className="cultureSectionWrap animated-section section1 section aosAnim "
      id="sec15"
    >
      <div className="scrollSection">
        <div className="container">
          <div className="cultureRow">
            <div className="imgBox">
              <img
                loading="lazy"
                src={culturePic}
                style={{ width: "100%" }}
                className="lazy"
                alt=""
              />
            </div>
            <div className="contBox">
              <h2 className="titleText">
                <span className="skyText">Culture</span> that values people
              </h2>
              <p>
                Where pride, passion, and purpose drive our community. We
                believe that culture and work ethics are the foundations of
                exponential growth
              </p>
              <div className="cultureList" style={{ marginBottom: "35px" }}>
                <div className="eachList">
                  <img loading="lazy" src={culturePic1} alt="" />
                  <strong>Flexibility</strong>
                </div>
                <div className="eachList">
                  <img loading="lazy" src={culturePic2} alt="" />
                  <strong>Relaxed Atmosphere</strong>
                </div>
                <div className="eachList">
                  <img loading="lazy" src={culturePic3} alt="" />
                  <strong>Open and honest communication</strong>
                </div>
                <div
                  onClick={() => navigate("/culture")}
                  className="section-btn"
                >
                  <button>Know More</button>
                  <img src={RightHighlightedArrow} alt="" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CultureSection;
