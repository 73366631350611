import React, { useEffect, useRef } from "react";
import "./index.css";
import img1 from "../../../../assets/images/csr/img2.jpg";
import img2 from "../../../../assets/images/csr/ScrapWarehouse.jpeg";

const SustainableMetal = () => {
  const aosRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("ltr");
          }
        });
      },
      {
        threshold: 0.3,
      }
    );

    if (aosRef.current) {
      observer.observe(aosRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (aosRef.current) {
        observer.unobserve(aosRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const aosElements = document.querySelectorAll(".aosAnim");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("ltr");
            // entry.target.classList.remove("ltr-out");
          } else {
            // entry.target.classList.add("ltr-out");
            // entry.target.classList.remove("ltr");
          }
        });
      },
      {
        threshold: 0.3,
        // rootMargin: "-10px",
      }
    );

    aosElements.forEach((el) => {
      observer.observe(el);
    });

    // Clean up the observer on component unmount
    return () => {
      aosElements.forEach((el) => {
        observer.unobserve(el);
      });
    };
  }, []);
  return (
    <>
      <div
        className="sustainableMetalCSR animated-section section pb-0"
        id="sec31a"
      >
        <div className="overflowSection">
          <div class="container">
            <div className="row">
              <div ref={aosRef} className="col-lg-12 aosAnim ">
                <div className="sechead keyHead aosAnim sustainableMetalCSR">
                  <h3>
                    Sustainable Metal Transformation:
                    <span> NowPurchase's Eco-Conscious Processing Center</span>
                  </h3>
                  <p>
                    NowPurchase's Scrap Processing Centre efficiently converts
                    metal scrap into various forms and shapes, considering its
                    physical and chemical traits. Our 30,000 sq. ft. facility
                    employs cutting-edge machinery for shearing, baling, and
                    cutting. We also utilize an Ametek Spectrometer to ensure
                    precise control over scrap chemistry to meet customer needs.
                    This brings standardization to the traditionally
                    disorganized metal scrap industry.
                  </p>
                </div>
                <div className="sustainableMetalPicSection">
                  <div style={{ flex: "1" }}>
                    <img
                      src={img1}
                      alt=""
                      style={{ minHeight: "100%", borderRadius: "15px" }}
                    />
                  </div>
                  <div style={{ flex: "1" }}>
                    <img
                      src={img2}
                      alt=""
                      style={{ minHeight: "100%", borderRadius: "15px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SustainableMetal;
