import React from "react";
import "./index.css";
const Strip = () => {
  return (
    <>
      <div className="stripheader">
        <p className="stripP">
          <img src="https://a.slack-edge.com/production-standard-emoji-assets/14.0/google-large/1f389@2x.png" />
          <strong>
            NowPurchase raises $6 million in Funding Led by InfoEdge Ventures.
          </strong>
          <a
            href="https://nowpurchase.com/NowPurchase-raises-USD-6-million-in-funding-led-by-Infoedge-Ventures"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read Now
          </a>
          <img src="https://a.slack-edge.com/production-standard-emoji-assets/14.0/google-large/1f389@2x.png" />
        </p>
      </div>
    </>
  );
};

export default Strip;
