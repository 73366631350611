import React, { useEffect, useRef } from "react";
import styles from "./NewsRoom.module.css";
import investor1 from "../../assets/images/logo/logo_1.png";
import InfoEdgeVentures from "../../assets/images/logo/InfoEdgeVentures.webp";
import DholakiaVentures from "../../assets/images/logo/DholakiaVentures.svg";
import VCLogo from "../../assets/images/logo/VC-Logo.png";
import UnicornsLogo from "../../assets/images/logo/100Unicorns.png";
import investor3 from "../../assets/images/logo/logo_3.png";
import { imagePaths } from "../../constants";
function MediaInvestor() {
  const aosRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("ltr");
            // entry.target.classList.remove("ltr-out");
          } else {
            // entry.target.classList.add("ltr-out");
            // entry.target.classList.remove("ltr");
          }
        });
      },
      {
        threshold: 0.3,
        // rootMargin: "-10px",
      }
    );

    if (aosRef.current) {
      observer.observe(aosRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (aosRef.current) {
        observer.unobserve(aosRef.current);
      }
    };
  }, []);
  return (
    <div
      ref={aosRef}
      className="investorSectionWrap fadedBackground animated-section section aosAnim"
    >
      <div className="scrollSection">
        <div className="container">
          <div className={styles.investorRow}>
            <h2 className="titleText">
              <span className="skyText">Join</span> our visionary <br />
              investor community
            </h2>
            <div className={styles.flexContainer}>
              <div className={styles.flexView}>
                <div className={styles.imgContainer}>
                  <img loading="lazy" src={investor1} alt="" />
                </div>

                <div className={styles.imgContainer}>
                  <img loading="lazy" src={InfoEdgeVentures} alt="" />{" "}
                </div>
                <div className={styles.imgContainer}>
                  <img loading="lazy" src={investor3} alt="" />
                </div>
              </div>
              <div className={styles.flexView}>
                <div className={styles.imgContainer}>
                  <img loading="lazy" src={DholakiaVentures} alt="" />
                </div>
                <div className={styles.imgContainer}>
                  <img loading="lazy" src={imagePaths.RealGroupLogo} alt="" />
                </div>
                <div className={styles.imgContainer}>
                  <img loading="lazy" src={UnicornsLogo} alt="" />
                </div>
                <div className={styles.imgContainer}>
                  <img loading="lazy" src={VCLogo} alt="" />
                </div>
              </div>
            </div>
            <div>
              ...and other angel investors like{" "}
              <strong>Subhrakant Panda</strong>, <strong>Ankur Warikoo</strong>{" "}
              , and <strong>Kedar Lele</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaInvestor;
