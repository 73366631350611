import React, { useEffect, useRef } from "react";
import HeroCareers from "./Hero";
import FoundersNote from "./FoundersNote";
import ExploreSection from "./ExploreSection";
import Disclaimer from "./Disclaimer";
import PrivilegeCards from "./Cards";
import EmployeeCards from "./EmployeeTestimoialCards";

function Careers() {
  const exploreRef = useRef(null);
  const careerReference = useRef(null);

  useEffect(() => {
    if (careerReference && careerReference.current) {
      careerReference.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div>
      <HeroCareers reference={exploreRef} />
      <FoundersNote />
      <Disclaimer reference={exploreRef} />
      <PrivilegeCards />
      <EmployeeCards />
      <ExploreSection ref={careerReference} />
    </div>
  );
}

export default Careers;
