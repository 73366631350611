import React, { useState } from "react";
import "./index.css";
import Lightbox from "yet-another-react-lightbox"; // Use any lightbox library you prefer
import "yet-another-react-lightbox/styles.css"; // Import lightbox styles
import img1 from "../../../assets/images/culture/c-Team1Big.png";
import img2 from "../../../assets/images/culture/c-Team2Big.png";
import img3 from "../../../assets/images/culture/c-Team3Big.png";
import img4 from "../../../assets/images/culture/c-Team4Big.png";
import img5 from "../../../assets/images/culture/c-Team5Big.png";
import img6 from "../../../assets/images/culture/c-Team6Big.png";
import img7 from "../../../assets/images/culture/c-Team7Big.png";
import img8 from "../../../assets/images/culture/c-Team8Big.png";
import img9 from "../../../assets/images/culture/c-Team9Big.png";
import img10 from "../../../assets/images/culture/c-Team10Big.png";
import { assetPath } from "../../../assets/s3Uploads";
import Container from "../../../Components/General/Container";

const mainImages = [
  { src: assetPath.CultureImg1 },
  { src: assetPath.CultureImg2 },
  { src: assetPath.CultureImg3 },
  { src: assetPath.CultureImg4 },
  { src: assetPath.CultureImg6 },
  { src: assetPath.CultureImg14 },
  { src: assetPath.CultureImg5 },
  { src: assetPath.CultureImg8 },
  { src: assetPath.CultureImg9 },
  { src: assetPath.CultureImg10 },
];
const moreImages = [
  { src: assetPath.CultureImg11 },
  { src: assetPath.CultureImg12 },
  { src: assetPath.CultureImg13 },
  { src: assetPath.CultureImg7 },
  { src: assetPath.CultureImg15 },
  { src: assetPath.CultureImg16 },
  { src: assetPath.CultureImg17 },
  { src: assetPath.CultureImg18 },
  { src: img1 },
  { src: img2 },
  { src: img3 },
  { src: img4 },
  { src: img5 },
  { src: img6 },
  { src: img7 },
  { src: img8 },
  { src: img9 },
  { src: img10 },
];

function ImageGallery() {
  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(mainImages.length); // Track the clicked image index

  const openLightboxAtIndex = (index) => {
    setStartIndex(index);
    setLightboxOpen(true);
  };

  return (
    <div className="teamCulture animated-section section" id="sec45">
      <div className="scrollSection1">
        <Container>
          <div className="gallery-container">
            <div className="grid-layout">
              <div
                className={`image-item`}
                onClick={() => openLightboxAtIndex(0)}
              >
                <img src={mainImages[0].src} alt="Gallery Image" />
              </div>
              <div className="child-grid">
                {mainImages.slice(1, 5).map((image, index) => {
                  return (
                    <div
                      onClick={() => openLightboxAtIndex(index + 1)}
                      className={`image-item`}
                      key={index}
                    >
                      <img src={image.src} alt={`Gallery Image ${index + 1}`} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="grid-layout">
              <div
                className={`image-item`}
                onClick={() => openLightboxAtIndex(5)}
              >
                <img src={mainImages[5].src} alt="Gallery Image" />
              </div>
              <div className="child-grid">
                {mainImages.slice(6).map((image, index) => {
                  const lastOverlayImage = index === mainImages.length - 7;
                  return (
                    <div
                      className={`image-item ${
                        lastOverlayImage ? "more-overlay" : ""
                      }`}
                      onClick={() =>
                        lastOverlayImage
                          ? setLightboxOpen(true)
                          : openLightboxAtIndex(index + 6)
                      }
                      key={index}
                    >
                      <img src={image.src} alt={`Gallery Image ${index + 1}`} />
                      {lastOverlayImage && (
                        <div className="overlay">
                          <span>+{moreImages.length} More</span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Lightbox for additional images */}
            {isLightboxOpen && (
              <Lightbox
                open={isLightboxOpen}
                close={() => setLightboxOpen(false)}
                slides={[...mainImages, ...moreImages]}
                index={startIndex}
              />
            )}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ImageGallery;
