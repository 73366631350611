import React from 'react'
import "./index.css"
import mapIcon from "../../../assets/images/career/mapIcon.svg";
import calenderIcon from "../../../assets/images/career/timerIcon.svg";
import recyclePic from "../../../assets/images/csr/flat-lay-recycling 2.png"
import csrImg from "../../../assets/images/csr/image 58.png"
function CSR() {
  return (
   <div className="event1  event2 csrSec csrSec1">
        <div class="container">
          <div class="eventHead aosAnim">
            <h3 class="titleText">Company Social Responsibility</h3>
            <p>
             Taking responsibility, creating positive change. We believe in making a lasting impact through our actions and commitments.
            </p>
          </div>
          <div class="eventFull aosAnim">
            <div class="row">
              <div class="col-md-6">
                <div class="eventImgM">
                  <img
                    loading="lazy"
                   
                    src={recyclePic}
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="tabContBox">
                  <div class="topBar">
                    <h4>Scrap Recycling</h4>
                  </div>
                  <div class="mainBox">
                    <div class="listBox">
                      <ul>
                        <li>
                          <img loading="lazy" src={mapIcon} alt="" />
                          <span>
                           India
                          </span>
                        </li>
                        <li>
                      
                        </li>
                      </ul>
                    </div>
                  </div>
                 
                </div> <a href="\scrap-recycling" class="npButton getInTouch" style={{color: "white"}}>Read More</a>
              </div>
            </div>
          </div>
          <div class="eventFull aosAnim">
            <div class="row">
              <div class="col-md-6">
                <div class="eventImgM">
                  <img
                    loading="lazy"
                   
                    src={csrImg}
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-6">

                <div class="tabContBox">
                  <div class="topBar">
                    <h4>Igniting Hope</h4>
                  </div>
                  <div class="mainBox">
                    <div class="listBox">
                      <ul>
                        <li>
                          <img loading="lazy" src={mapIcon} alt="" />
                          <span>Kolkata, West Bengal</span>
                        </li>
                        <li>
                          <img loading="lazy" src={calenderIcon} alt="" />
                          <span>25-26 March, 2023</span>
                        </li>
                      </ul>
                    </div>
                     
                  </div>
                 
                </div> <a href="\educational-empowerement" class="npButton getInTouch" style={{color: "white"}}>Read More</a>
          
              </div>
            </div>
          </div>
       
        </div>
      </div>
  )
}

export default CSR