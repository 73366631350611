import React from "react";
import styles from "./index.module.css";
import { imagePaths } from "../../constants";
import Container from "../../Components/General/Container";

const FundingRelease = () => {
  return (
    <Container>
      <div className={styles.fundingContainer}>
        <div className={styles.fundingHeader}>
          NowPurchase a SaaS-Enabled Marketplace for Metal Manufacturers raises
          $6 million in Funding Led by Info Edge Ventures, with participation
          from Orios Ventures Partners, 100 Unicorns & VC Grid
        </div>
        <img
          className={styles.fundingImg}
          src={imagePaths.FundingMainImage}
          alt="Press Release"
        />
        <div className={styles.description}>
          <strong>
            Kolkata, September 19, 2024 -
            <a
              href="https://nowpurchase.com/"
              target="blank"
              rel="noopener noreferrer"
            >
              {" "}
              NowPurchase
            </a>
            ,
          </strong>{" "}
          a SaaS-enabled{" "}
          <a href="/marketplace" target="blank" rel="noopener noreferrer">
            {" "}
            MarketPlace
          </a>{" "}
          for metal manufacturers today announced that it has secured a funding
          of $6 million that includes both equity and debt. The majority of the
          funds were raised through equity, with Info Edge Ventures leading the
          round. Other notable participants include Orios Ventures Partners, 100
          Unicorns, VC Grid, as well as family offices and angel investors such
          as Dholakia Ventures, Real Ispat Group, Subhrakant Panda, Ankur
          Warikoo, and Kedar Lele. Additionally, partners like Capsave Finance
          and UC Inclusive contributed to this round, bringing the total funding
          raised to date to $10 million. NowPurchase is a procurement solution
          where Metal Manufacturers can procure high quality raw materials like
          metal scrap, alloys and additives in a trusted & transparent manner.
          It also provides its users with a WhatsApp bot to discover prices and
          stock in real time, a team to provide on-ground service and quality
          assurance, and a proprietary SaaS platform{" "}
          <a href="/application" target="blank" rel="noopener noreferrer">
            {" "}
            MetalCloud
          </a>{" "}
          to optimize their manufacturing process. The funds raised will be
          allocated to various strategic initiatives, including expanding
          geographically into more clusters across India and launching new
          solutions to better serve the metal manufacturing industry.
          Furthermore, the funds will support the creation of a network of scrap
          processing centers, development of private labels, and the integration
          of AI and MetalCloud as the foundational operating system for metal
          manufacturing.
        </div>
        <div className={styles.description}>
          <strong> Naman Shah, Founder & CEO, NowPurchase</strong>
          <div className={styles.italicsText}>
            "The disorganized and fragmented nature of this sector presents a
            prime opportunity for disruption. We are more confident than ever
            about this potential and are excited to roll out our solution to
            metal manufacturers nationwide. With 2x year-over-year growth over
            the past three years, we have demonstrated the strength of our
            business model and the vast market potential. Our SaaS layer,
            MetalCloud has seen a tremendous response in the last 9 months with
            more than 100 factories actively using it across the country. We
            believe this would be a game changer and a strong MOAT as we scale”.
          </div>
        </div>
        <div className={styles.description}>
          <strong> Aakash Shah, Co-Founder, NowPurchase</strong>
          <div className={styles.italicsText}>
            “We're dreaming big while staying grounded in execution. We're
            thrilled about the core team we've assembled and are looking forward
            to the next phase of growth in the upcoming quarters."
          </div>
        </div>
        <div className={styles.description}>
          <strong> Kitty Agarwal, Partner at Info Edge Ventures </strong>
          <div className={styles.italicsText}>
            “The fragmentation of the metal sector creates a significant
            opportunity for companies like NowPurchase. Their innovative
            solutions are leading the charge in addressing the industry's most
            critical challenges. With a remarkable record of consistent
            year-over-year growth and a strong emphasis on effective business
            controls and metrics, NowPurchase showcases their capability to
            execute and scale successfully.”
          </div>
        </div>
        <div className={styles.description}>
          <strong>
            Rehan Yar Khan, Managing Partner, Orios Venture Partners
          </strong>
          <div className={styles.italicsText}>
            “The supply chain of the metals industry had not moved into the
            internet age and that is what NowPurchase set out to bring about. We
            were excited at the potential of the business when we met the
            founders and first invested a few years ago. We have seen the team
            execute carefully and build the business well and thus were happy to
            participate in this round. The story of digitizing the metals supply
            chain is yet in its infancy and we believe in the years to come a
            very large business should develop”
          </div>
        </div>
        <div className={styles.footer}>
          <strong>About NowPurchase:</strong>
          <div className={styles.description}>
            NowPurchase was founded in 2017 by Naman Shah and Aakash Shah, with
            an initial investment of $300K from Nipha Group. The company draws
            on Naman's experience with multiple startups in the US and
            Singapore, where he witnessed firsthand the transformative power of
            technology for businesses. Aakash’s decade plus manufacturing
            experience has helped the company with its supply chain & building
            the right solutions for metal manufacturers. With a comprehensive
            range of services and solutions, NowPurchase aims to expand its
            geographic footprint and become the leading procurement platform for
            all metal manufacturers. Ankan Adhikari joined the core team in 2019
            as a CTO. Ankan is a serial entrepreneur who’s previous EdTech
            venture was acquired by UpGrad.
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FundingRelease;
