import React, { useEffect, useRef } from "react";
import "./index.css";
import TreePlantation1 from "../../../../assets/images/csr/TreePlantation1.png";
import TreePlantation2 from "../../../../assets/images/csr/TreePlantation2.png";
import TreePlantation3 from "../../../../assets/images/csr/TreePlantation3.png";
import TreePlantation4 from "../../../../assets/images/csr/TreePlantation4.png";
import Container from "../../../General/Container";
const TreePlantation = () => {
  const aosRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("ltr");
          }
        });
      },
      {
        threshold: 0.3,
      }
    );

    if (aosRef.current) {
      observer.observe(aosRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (aosRef.current) {
        observer.unobserve(aosRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const aosElements = document.querySelectorAll(".aosAnim");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("ltr");
            // entry.target.classList.remove("ltr-out");
          } else {
            // entry.target.classList.add("ltr-out");
            // entry.target.classList.remove("ltr");
          }
        });
      },
      {
        threshold: 0.3,
        // rootMargin: "-10px",
      }
    );

    aosElements.forEach((el) => {
      observer.observe(el);
    });

    // Clean up the observer on component unmount
    return () => {
      aosElements.forEach((el) => {
        observer.unobserve(el);
      });
    };
  }, []);
  return (
    <>
      <div className=" animated-section section">
        <Container>
          <div className="row">
            <div ref={aosRef} className=" col-lg-12 aosAnim">
              <div class="sechead keyHead aosAnim">
                <h3>
                  <span>
                    Our Tree Plantation Drive will release 15+tonnes of oxygen
                    every year
                  </span>
                </h3>
              </div>
              <div className="treePlantationImgSection">
                <div className="keyWrapTP">
                  <img src={TreePlantation1} alt="" />
                </div>
                <div className="keyWrapTP">
                  <img src={TreePlantation2} alt="" />
                </div>
                <div className="keyWrapTP">
                  <img src={TreePlantation3} alt="" />
                </div>
                <div className="keyWrapTP">
                  <img src={TreePlantation4} alt="" />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="disclaimerSectionWrap animated-section section">
        <div className="container">
          <div className="row disclaimerWrap aosAnim">
            <div className="col-lg-12">
              <div className="founderCont">
                <div className="founderContBox treeplantation">
                  <h2 className="titleText">
                    <span className="skyText">Join Us </span>
                    on the Journey to
                    <span className="skyText"> Embrace Sustainability</span> &
                    <span className="skyText"> Preserve our Environment </span>{" "}
                  </h2>
                  <p>
                    Procure tailor-made scrap from NowPurchase to seamlessly
                    integrate it into your manufacturing workflow, making an
                    environmentally responsible choice and actively contributing
                    to a more sustainable future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TreePlantation;
