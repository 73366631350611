import React, { useRef } from "react";
import "./index.css";
import arrow from "../../../assets/images/marketplace/test-arrow.png";
import p1 from "../../../assets/images/people/people_10.png";
import p2 from "../../../assets/images/people/people_11.png";
import p3 from "../../../assets/images/people/people_12.png";
import p4 from "../../../assets/images/people/people_13.png";
import p5 from "../../../assets/images/people/people_14.png";
import Container from "../../../Components/General/Container";
import Slider from "react-slick";
import { shuffleArray } from "../../../Components/utils/utils";

const testimonials = [
  {
    quote: (
      <>
        The support and opportunities at <b>NowPurchase</b> have greatly
        contributed to my growth. A highlight was presenting MetalCloud at the
        World Foundry Organization Young Researchers Conference in Hannover,
        Germany—an incredible experience. I am proud to be part of this
        innovative team and excited to continue pushing the boundaries of
        engineering design and research.
      </>
    ),
    empImg: p1,
    name: "Monish Kumar VR",
    careerPath: [
      {
        date: "10 Sep, 2021",
        title: "R&D Quality Engineer",
      },
      {
        date: "01 Apr, 2022",
        title: "Sr. R&D Metallurgical Engineer",
      },
      {
        date: "01 Jan, 2023",
        title: "Lead Engineering Designer",
      },
    ],
  },
  {
    quote: (
      <>
        <b>NowPurchase's</b> innovative and challenging environment has kept my
        passion for work strong, honing my technical skills and developing my
        leadership abilities. The culture of continuous improvement makes every
        day an opportunity to excel.
      </>
    ),
    name: "Nishant Singh",
    empImg: p2,

    careerPath: [
      {
        date: "01 Apr, 2016",
        title: "Front End Developer",
      },
      {
        date: "19 Sep, 2017",
        title: "Assistant Engineering Manager",
      },
      {
        date: "01 Jan, 2021",
        title: "Engineering Manager",
      },
      {
        date: "01 Jan, 2022",
        title: "Senior Engineering Manager",
      },
    ],
  },
  {
    quote: (
      <>
        The mentorship and collaborative culture at <b>NowPurchase</b> have
        played a pivotal role in my growth, enabling me to achieve both team and
        personal milestones.
      </>
    ),
    name: "Rainark Saha",
    empImg: p3,

    careerPath: [
      {
        date: "17 Sep, 2018",
        title: "Executive-Accounts",
      },
      {
        date: "01 Jan, 2022",
        title: "Senior Executive-Accounts",
      },
      {
        date: "30 Jul, 2022",
        title: "SE - AR & Credit Analysis",
      },
      {
        date: "01 Jan, 2024",
        title: "AM- AR & Credit Analysis",
      },
    ],
  },
  {
    quote: (
      <>
        Over the past five years at <b>NowPurchase</b>, I transitioned from
        operations to sales management, contributing to the company's growth and
        developing professionally and personally. This journey has equipped me
        with invaluable expertise and confidence.
      </>
    ),
    name: "Rayhan Islam",
    empImg: p4,

    careerPath: [
      {
        date: "20 Apr, 2019",
        title: "Executive- Sales",
      },
      {
        date: "01 Jan, 2021",
        title: "Assistant Manager-Sales",
      },
      {
        date: "01 Jan, 2022",
        title: "Deputy Manager-Sales",
      },
      {
        date: "01 Jan, 2023",
        title: "Manager -Sales",
      },
    ],
  },
  {
    quote:
      "Throughout my tenure, I have consistently felt a sense of entrepreneurship and ownership in my work. The supportive team and management have been instrumental in my longevity and success, fostering an environment that encourages continuous improvement and high performance.",
    name: "Sachin Singh",
    empImg: p5,

    careerPath: [
      {
        date: "01 Jun, 2018",
        title: "Manager Sales",
      },
      {
        date: "01 Jan, 2021",
        title: "General Manager- Sales",
      },
      {
        date: "01 Jan, 2022",
        title: "Business Head-WB Cluster",
      },
      {
        date: "01 Jan, 2023",
        title: "Business Head - WB & MH",
      },
    ],
  },
];

const CareerTimeline = ({ careerPath }) => {
  return (
    <div className="timeline">
      {careerPath.map((item, index) => (
        <div key={index} className="timeline-item">
          <div
            className="timeline-dot"
            data-dot={
              index === 0 || index === careerPath.length - 1 ? "border" : ""
            }
          ></div>
          <div className="timeline-date">{item.date}</div>
          <div className="timeline-title">{item.title}</div>
        </div>
      ))}
      <div className="timeline-line"></div>
    </div>
  );
};

const CareerTimelineMobile = ({ careerPath }) => {
  return (
    <div className="timelineMobile">
      {careerPath.map((item, index) => (
        <>
          <div
            key={index}
            className="timelineMobile-item"
            style={
              index === 0 || index === careerPath.length - 1
                ? { margin: "0px 0px " }
                : { margin: "0px 10px" }
            }
          >
            <div
              className="timelineMobile-dot"
              data-dot={
                index === 0 || index === careerPath.length - 1 ? "border" : ""
              }
            ></div>
            <div className="timelineMobile-date">{item.date}</div>
            <div className="timelineMobile-title">{item.title}</div>
          </div>
          {index != careerPath.length - 1 && (
            <div className="timelineMobile-line"></div>
          )}
        </>
      ))}
    </div>
  );
};

const TestimonialCard = ({ quote, name, empImg, careerPath }) => (
  <div className="testCard1">
    <div class="cTestImage">
      <img src={empImg} style={{ width: "100%" }} alt="" />
    </div>
    <div class="testDetail">
      <div class="testIc">
        <div class="testName">
          <h4>{name}</h4>
        </div>
      </div>
    </div>
    <p>{quote}</p>
    <CareerTimeline careerPath={careerPath} />
    <CareerTimelineMobile careerPath={careerPath} />
  </div>
);

const LifeAtNPCards = () => {
  const sliderRef = useRef(null);

  const goToPrevious = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div
        className="testSec testCulture animated-section section sectionCards aosAnim ltr "
        id="sec16"
      >
        <div className="scrollSection1">
          <div className="container">
            <div className="testSecWrap">
              <div class="testmain d-flex justify-content-between align-items-end">
                <div
                  class="sechead keyHead mb-0 aos-init aos-animate"
                  data-aos="fade-right"
                >
                  <h3>
                    <span>Employee </span>
                    Testimonials
                  </h3>
                </div>
                <div
                  class="test-nav nav-style-one aos-init aos-animate"
                  data-aos="fade-left"
                >
                  <div
                    class="test_arrow test-prev prev swiper-button-disabled"
                    id="Culture_Section4_Testimonials_ArrowLeft"
                    tabindex="-1"
                    role="button"
                    onClick={goToPrevious}
                    style={{ userSelect: "none" }}
                  >
                    <img loading="lazy" src={arrow} alt="" />
                  </div>
                  <div
                    class="test_arrow test-next next"
                    id="Culture_Section4_Testimonials_ArrowRight"
                    tabindex="0"
                    role="button"
                    onClick={goToNext}
                    style={{ userSelect: "none" }}
                  >
                    <img loading="lazy" src={arrow} alt="" />
                  </div>
                </div>
              </div>

              <Slider ref={sliderRef} {...sliderSettings}>
                {shuffleArray(testimonials).map((testimonial, index) => (
                  <div className="swiper testSlider">
                    <div className="swiper-slide">
                      <TestimonialCard key={index} {...testimonial} />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LifeAtNPCards;
