import React, { useState } from "react";
import styles from "./NewsRoom.module.css";
import RightArrow from "../../assets/images/icons/rgt_arrow_vector_bl.png";

const MediaCardMobileView = ({ displayItems }) => {
  const [viewMore, setViewMore] = useState(false);
  return (
    <div className={styles.mediaColumnView}>
      {displayItems?.slice(0, 6).map((item, index) => (
        <a
          key={index}
          href={item.redirectLink}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.mediaCardView}
        >
          <div className={styles.mediaCardText}>{item.title}</div>
          <div className={styles.mediaCardImg}>
            <img src={item.image} alt={item.title} />
          </div>
        </a>
      ))}
      {viewMore &&
        displayItems.length > 6 &&
        displayItems.slice(6).map((item, index) => (
          <a
            key={index}
            href={item.redirectLink}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.mediaCardView}
          >
            <div className={styles.mediaCardText}>{item.title}</div>
            <div className={styles.mediaCardImg}>
              <img src={item.image} alt={item.title} />
            </div>
          </a>
        ))}
      {displayItems.length > 6 && (
        <div
          onClick={() => setViewMore((prev) => !prev)}
          className={styles.viewBtnContainer}
        >
          <button className={styles.viewMoreBtn}>{`View ${
            !viewMore ? "More" : "Less"
          }`}</button>
          <img
            src={RightArrow}
            alt=""
            style={{ transform: `rotate(${!viewMore ? "90deg" : "-90deg"})` }}
          />
        </div>
      )}
    </div>
  );
};

export default MediaCardMobileView;
