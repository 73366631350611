import React, { useEffect, useRef } from "react";
import "./index.css";
import { countEnv } from "../../../constants";
import CountUp from "react-countup";
import Container from "../../../General/Container";
const MetalScrapEnv = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("ltr");
            // entry.target.classList.remove("ltr-out");
          } else {
            // entry.target.classList.add("ltr-out");
            // entry.target.classList.remove("ltr");
          }
        });
      },
      {
        threshold: 0.3,
        // rootMargin: "-10px",
      }
    );

    if (aosRef.current) {
      observer.observe(aosRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (aosRef.current) {
        observer.unobserve(aosRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("aosAnim1");
            // entry.target.classList.remove("ltr-out");
          } else {
            // entry.target.classList.add("ltr-out");
            // entry.target.classList.remove("ltr");
          }
        });
      },
      {
        threshold: 0.3,
        // rootMargin: "-10px",
      }
    );

    if (aosRef1.current) {
      observer.observe(aosRef1.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (aosRef1.current) {
        observer.unobserve(aosRef1.current);
      }
    };
  }, []);
  const aosRef = useRef(null);
  const aosRef1 = useRef(null);
  return (
    <section className="keyNumber animated-section section " id="">
      <Container>
        <div ref={aosRef} className="sechead keyHead aosAnim">
          <h3>
            <span>
              Make a Responsible Choice with Metal Scrap for Environmental
              Preservation
            </span>
          </h3>
          <p>
            <span className="gradientText">Conserves over 1.1+ Tonne </span>
            of iron ore
            <br />
            and saves 630 kg of coking coal and 55 kg of limestone <br />
            per tonne of scrap
          </p>
        </div>

        <div ref={aosRef1} className="keyWrap keyWrap1 keyWrapEnv">
          {countEnv.map(({ number, text, title, src }) => (
            <div className="eachkey">
              <div className="iEnv">
                <img src={src} alt="" />
              </div>
              <p className="pEnv">
                <br />
                <span className="counter" style={{ color: "#2BA24C" }}>
                  <CountUp end={number} duration={8} /> {text}
                </span>
                {title}
              </p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default MetalScrapEnv;
